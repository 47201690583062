/*
Add Smart Logic Controller device
*/

import React from "react";

const AddSLC = (props) => {
  return (
    <form>
      <h5>Smart Logic Controller</h5>
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Name
        </label>
        <input
          type="text"
          className="form-control"
          id="name"
          aria-describedby="nameHelp"
          value={props.name}
          onChange={(e) => props.setName(e.target.value)}
        />
        <div id="nameHelp" className="form-text">
          Name of the device
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="owner" className="form-label">
          Device ID
        </label>
        <input
          type="text"
          className="form-control"
          id="device_id"
          aria-describedby="deviceIdHelp"
          value={props.sn}
          onChange={(e) => props.setSN(e.target.value)}
        />
        <div id="deviceIdHelp" className="form-text">
          Serial number of the device
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="ems" className="form-label">
          Energy Management System
        </label>
        <select
          className="form-select"
          id="ems"
          aria-describedby="emsHelp"
          value={props.emsDevice}
          onChange={(e) => props.setEMSDevice(e.target.value)}>
          {props.emsDevices.map((ems) => (
            <option value={ems._id}>
              {ems.name +
                " (" +
                ems.owner.name +
                " " +
                ems.owner.lastname +
                ")"}
            </option>
          ))}
        </select>
        <div id="emsHelp" className="form-text">
          Energy Management System device
        </div>
      </div>
    </form>
  );
};

export default AddSLC;
