import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthProvider";
import ErrorToast from "../Toasts/ErrorToast";

import { useTranslation } from "react-i18next";

const api_url = process.env.REACT_APP_API_URL;

export const LoginForm = () => {
  const { t } = useTranslation("global");
  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [email, setEmail] = useState({
    value: "",
    valid: false,
    touched: false,
    errMsg: "",
  });
  const [password, setPassword] = useState({
    value: "",
    valid: false,
    touched: false,
    errMsg: "",
  });

  const login = (e) => {
    e.preventDefault();
    if (
      email.value === "" ||
      password.value === "" ||
      !email.valid ||
      !password.valid
    ) {
      setEmail({
        ...email,
        touched: true,
        valid: false,
        errMsg: t("login.email_invalid"),
      });
      setPassword({
        ...password,
        touched: true,
        valid: false,
        errMsg: t("login.password_invalid"),
      });
    } else {
      setSubmitting(true);
      postLogin();
    }
  };

  const postLogin = () => {
    fetch(api_url + "/user/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email.value,
        password: password.value,
      }),
    }).then((res) => {
      if (res.status !== 200) {
        res.json().then((data) => {
          console.log(data);
          setError(true);
          setErrorMsg(t("login.error"));
          setSubmitting(false);
        });
      } else {
        res.json().then((data) => {
          console.log(data);
          localStorage.setItem("token", data.token);
          context.setIsAuth(true);
          context.setToken(data.token);
          context.setUser(data.user);
          setSubmitting(false);
          navigate("/");
        });
      }
    });
  };

  const handleEmailChange = (e) => {
    e.preventDefault();
    if (
      e.target.value === "" ||
      e.target.value === null ||
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)
    ) {
      setEmail({
        value: e.target.value,
        valid: false,
        touched: true,
        errMsg: t("login.email_invalid"),
      });
    } else {
      setEmail({
        value: e.target.value,
        valid: true,
        touched: true,
        errMsg: "",
      });
    }
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();
    if (e.target.value === "" || e.target.value === null) {
      setPassword({
        value: e.target.value,
        valid: false,
        touched: true,
        errMsg: t("login.password_invalid"),
      });
    } else {
      setPassword({
        value: e.target.value,
        valid: true,
        touched: true,
        errMsg: "",
      });
    }
  };

  return (
    <div>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "80vh" }}>
        {error && <ErrorToast text={errorMsg} handler={setError} />}
        <div
          className="card bg-dark text-white"
          style={{ borderRadius: "1rem" }}>
          <div className="card-body p-5 text-center">
            <div className="mb-md-5 mt-md-4 pb-5">
              <h2 className="fw-bold mb-2 text-uppercase">
                {t("login.title")}
              </h2>
              <p className="text-white-50 mb-5">{t("login.description")}</p>
              <div className="form-outline form-white mb-3">
                <label className="form-label" htmlFor="typeEmailX">
                  {t("login.email")}
                </label>
                <input
                  type="email"
                  id="typeEmailX"
                  className={
                    "form-control form-control-lg" +
                    (email.touched
                      ? email.valid
                        ? " is-valid"
                        : " is-invalid"
                      : "")
                  }
                  placeholder={t("login.email_placeholder")}
                  onChange={handleEmailChange}
                  value={email.value}
                />
                <div className="invalid-feedback">
                  {email.errMsg ? email.errMsg : t("login.email_invalid")}
                </div>
                <div className="valid-feedback">{t("login.email_valid")}</div>
              </div>

              <div className="form-outline form-white mb-3">
                <label className="form-label" htmlFor="typePassword">
                  {t("login.password")}
                </label>
                <input
                  type="password"
                  id="typePassword"
                  className={
                    "form-control form-control-lg" +
                    (password.touched
                      ? password.valid
                        ? " is-valid"
                        : " is-invalid"
                      : "")
                  }
                  placeholder={t("login.password_placeholder")}
                  onChange={handlePasswordChange}
                  value={password.value}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      login(e);
                    }
                  }}
                />

                <div className="invalid-feedback">
                  {password.errMsg
                    ? password.errMsg
                    : t("login.password_invalid")}
                </div>

                <div className="valid-feedback">
                  {t("login.password_valid")}
                </div>
              </div>

              <p className="small mb-3 pb-lg-2">
                <a className="text-white-50" href="#!">
                  {t("login.forgot_password")}
                </a>
              </p>

              <button
                className={
                  "btn btn-outline-light btn-lg px-5" +
                  (submitting ? " disabled" : "")
                }
                type="submit"
                onClick={login}>
                {submitting ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"></span>
                ) : (
                  t("login.submit")
                )}
              </button>
            </div>
            <div>
              <p className="mb-0">
                {t("login.register_quote")}{" "}
                <a href="/register" className="text-white-50 fw-bold">
                  {t("login.sign_up")}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
