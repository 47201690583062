import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddDevice from "../components/AddDevice/AddDevice";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../context/AuthProvider";
import { getFetcher } from "../components/fetcher";

//const api_url = process.env.REACT_APP_API_URL;

const Devices = () => {
  const { t } = useTranslation("global");
  const context = useContext(AuthContext);
  const [devices, setDevices] = useState([]);
  const [sharedDevices, setSharedDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allDevices, setAllDevices] = useState([]);
  const [allArchivedDevices, setAllArchivedDevices] = useState([]);

  useEffect(() => {
    setLoading(true);
    loadDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDevices = () => {
    getFetcher("/devices").then((data) => {
      setDevices(data.active_devices);
      setSharedDevices(data.shared_devices);
      if (data.all_devices) {
        console.log("all devices", data.all_devices);
        setAllDevices(data.all_devices);
      }
      if (data.archived_devices) {
        setAllArchivedDevices(data.archived_devices);
      }
      setLoading(false);
    });
  };

  const showDevices = () => {
    return (
      <table class="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            {/*<th scope="col">{t("devices.device_id")}</th>*/}
            <th scope="col">{t("devices.device_name")}</th>
            <th scope="col">{t("devices.device_owner")}</th>
            <th scope="col">{t("devices.device_type")}</th>
          </tr>
        </thead>
        <tbody>
          {devices.map((_, index) => {
            return (
              <>
                <tr>
                  <th scope="row">
                    <Link to={"/device/" + devices[index]._id}>
                      <button type="button" className="btn btn-success m-1">
                        {t("devices.device_show")}
                      </button>
                    </Link>
                  </th>
                  {/*<th scope="row">{devices[index]._id}</th>*/}
                  <th scope="row">{devices[index].name}</th>
                  <th scope="row">
                    {devices[index].owner.name +
                      " " +
                      devices[index].owner.lastname}
                  </th>
                  <th scope="row">{devices[index].type}</th>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    );
  };

  const showSharedDevices = () => {
    return (
      <table class="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            {/*<th scope="col">{t("devices.device_id")}</th>*/}
            <th scope="col">{t("devices.device_name")}</th>
            <th scope="col">{t("devices.device_owner")}</th>
            <th scope="col">{t("devices.device_type")}</th>
          </tr>
        </thead>
        <tbody>
          {sharedDevices.map((_, index) => {
            return (
              <>
                <tr>
                  <th scope="row">
                    <Link to={"/device/" + sharedDevices[index]._id}>
                      <button type="button" class="btn btn-success m-1">
                        {t("devices.device_show")}
                      </button>
                    </Link>
                  </th>
                  {/*<th scope="row">{sharedDevices[index]._id}</th>*/}
                  <th scope="row">{sharedDevices[index].name}</th>
                  <th scope="row">
                    {sharedDevices[index].owner.name +
                      " " +
                      sharedDevices[index].owner.lastname}
                  </th>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    );
  };

  const showAllDevices = () => {
    return (
      <table class="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            {/*<th scope="col">{t("devices.device_id")}</th>*/}
            <th scope="col">{t("devices.device_name")}</th>
            <th scope="col">{t("devices.device_owner")}</th>
            <th scope="col">{t("devices.device_type")}</th>
            <th scope="col">{t("devices.device_type")}</th>
            <th scope="col">{"ems"}</th>
          </tr>
        </thead>
        <tbody>
          {allDevices.map((_, index) => {
            return (
              <>
                <tr>
                  <th scope="row">
                    <Link to={"/device/" + allDevices[index]._id}>
                      <button type="button" class="btn btn-success m-1">
                        {t("devices.device_show")}
                      </button>
                    </Link>
                  </th>
                  {/*<th scope="row">{allDevices[index]._id}</th>*/}
                  <th scope="row">{allDevices[index].name}</th>
                  <th scope="row">
                    {allDevices[index].owner.name +
                      " " +
                      allDevices[index].owner.lastname}
                  </th>
                  <th scope="row">{allDevices[index].type}</th>
                  <th scope="row">{allDevices[index].config.type}</th>
                  {allDevices[index].ems && allDevices[index].ems.name}
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    );
  };

  const showAllArchivedDevices = () => {
    return (
      <table class="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            {/*<th scope="col">{t("devices.device_id")}</th>*/}
            <th scope="col">{t("devices.device_name")}</th>
            <th scope="col">{t("devices.device_owner")}</th>
            <th scope="col">{t("devices.device_type")}</th>
          </tr>
        </thead>
        <tbody>
          {allArchivedDevices.map((_, index) => {
            return (
              <>
                <tr>
                  <th scope="row">
                    <Link to={"/device/" + allArchivedDevices[index]._id}>
                      <button type="button" class="btn btn-success m-1">
                        {t("devices.device_show")}
                      </button>
                    </Link>
                  </th>
                  {/*<th scope="row">{allArchivedDevices[index]._id}</th>*/}
                  <th scope="row">{allArchivedDevices[index].name}</th>
                  <th scope="row">
                    {allArchivedDevices[index].owner.name +
                      " " +
                      allArchivedDevices[index].owner.lastname}
                  </th>
                  <th scope="row">{allArchivedDevices[index].type}</th>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    );
  };

  if (loading) {
    return (
      <div className="container">
        <h1 className="text-white text-center pt-5">{t("general.loading")}</h1>
      </div>
    );
  } else {
    return (
      <div className="container">
        <h1 className="text-white text-center pt-5">{t("devices.title")}</h1>
        {context.user.role.includes("admin") ||
        context.user.role.includes("intaller") ? (
          <AddDevice />
        ) : null}
        <div class="accordion" id="accordionPanelsStayOpenExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseOne"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseOne">
                {t("devices.my_devices")}
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="panelsStayOpen-headingOne">
              <div class="accordion-body">{showDevices()}</div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseTwo">
                {t("devices.shared_devices")}
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingTwo">
              <div class="accordion-body">{showSharedDevices()}</div>
            </div>
          </div>
          {allDevices.length > 0 && (
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseThree">
                  {t("devices.all_devices")}
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingThree">
                <div class="accordion-body">{showAllDevices()}</div>
              </div>
            </div>
          )}
          {allArchivedDevices.length > 0 && (
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingFour">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseFour"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseFour">
                  {t("devices.archived_devices")}
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingFour">
                <div class="accordion-body">{showAllArchivedDevices()}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
};
export default Devices;
