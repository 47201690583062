import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthProvider";
import ErrorToast from "../Toasts/ErrorToast";

import { useTranslation } from "react-i18next";

const api_url = process.env.REACT_APP_API_URL;

export const RegisterForm = () => {
  const { t } = useTranslation("global");
  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [name, setName] = useState({
    value: "",
    valid: false,
    touched: false,
    errMsg: "",
  });
  const [lastname, setLastname] = useState({
    value: "",
    valid: false,
    touched: false,
    errMsg: "",
  });
  const [email, setEmail] = useState({
    value: "",
    valid: false,
    touched: false,
    errMsg: "",
  });
  const [password, setPassword] = useState({
    value: "",
    valid: false,
    touched: false,
    errMsg: "",
  });
  const [password2, setPassword2] = useState({
    value: "",
    valid: false,
    touched: false,
    errMsg: "",
  });
  const postRegister = () => {
    const user = {
      name: name.value,
      lastname: lastname.value,
      email: email.value,
      password: password.value,
    };
    fetch(api_url + "/user/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    }).then((res) => {
      if (res.status === 409) {
        res.json().then((data) => {
          console.log(data);
          setError(true);
          setErrorMsg(String(data.errors));
          setEmail({
            ...email,
            touched: true,
            valid: false,
            errMsg: t("register.email_exists"),
          });
          setSubmitting(false);
        });
      } else {
        if (res.status !== 201) {
          res.json().then((data) => {
            console.log(data);
            setError(true);
            setErrorMsg(t("register.error"));
            setSubmitting(false);
          });
        } else {
          res.json().then((data) => {
            console.log(data);
            localStorage.setItem("token", data.token);
            context.setIsAuth(true);
            context.setToken(data.token);
            context.setUser(data.user);
            setSubmitting(false);
            navigate("/");
          });
        }
      }
    });
  };

  const register = (e) => {
    e.preventDefault();

    if (
      name.valid &&
      lastname.valid &&
      email.valid &&
      password.valid &&
      password2.valid
    ) {
      setSubmitting(true);
      postRegister();
    } else {
      alert(t("register.description"));
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;

    if (id === "name") {
      setName({
        ...name,
        value: value,
        valid: value.length > 0,
        touched: true,
        errMsg: t("register.name_required"),
      });
    } else if (id === "lastname") {
      setLastname({
        ...lastname,
        value: value,
        valid: value.length > 0,
        touched: true,
        errMsg: t("register.lastname_required"),
      });
    } else if (id === "email") {
      if (value.length === 0) {
        setEmail({
          ...email,
          value: value,
          valid: false,
          touched: true,
          errMsg: t("register.email_required"),
        });
      } else if (!/\S+@\S+\.\S+/.test(value)) {
        setEmail({
          ...email,
          value: value,
          valid: false,
          touched: true,
          errMsg: t("register.email_invalid"),
        });
      } else {
        setEmail({
          ...email,
          value: value,
          valid: true,
          touched: true,
          errMsg: "",
        });
      }
    } else if (id === "password") {
      if (value.length === 0) {
        setPassword({
          ...password,
          value: value,
          valid: false,
          touched: true,
          errMsg: t("register.password_required"),
        });
      } else if (value.length < 8) {
        setPassword({
          ...password,
          value: value,
          valid: false,
          touched: true,
          errMsg: t("register.password_invalid_length"),
        });
      } else if (!/\d/.test(value)) {
        setPassword({
          ...password,
          value: value,
          valid: false,
          touched: true,
          errMsg: t("register.password_invalid_number"),
        });
      } else {
        setPassword({
          ...password,
          value: value,
          valid: true,
          touched: true,
          errMsg: "",
        });
      }
    } else if (id === "password2") {
      if (value.length === 0) {
        setPassword2({
          ...password2,
          value: value,
          valid: false,
          touched: true,
          errMsg: t("register.password_required"),
        });
      } else if (value === password.value) {
        setPassword2({
          ...password2,
          value: value,
          valid: true,
          touched: true,
          errMsg: "",
        });
      } else {
        setPassword2({
          ...password2,
          value: value,
          valid: false,
          touched: true,
          errMsg: t("register.password_invalid_match"),
        });
      }
    }
  };

  return (
    <div>
      <div
        className="d-flex align-items-center justify-content-center pt-5"
        style={{ height: "80vh" }}>
        {error && <ErrorToast text={errorMsg} handler={setError} />}
        <div
          className="card bg-dark text-white"
          style={{ borderRadius: "1rem" }}>
          <div className="card-body text-center">
            <div className="mb-md-1 mt-md-4 px-2 mx-4">
              <h2 className="fw-bold mb-2 text-uppercase">
                {t("register.title")}
              </h2>

              <div className="form-outline form-white mb-2">
                <label className="form-label" htmlFor="name">
                  {t("register.name")}
                </label>
                <input
                  type="text"
                  id="name"
                  className={
                    "form-control form-control-lg h-50" +
                    (name.touched
                      ? name.valid
                        ? " is-valid"
                        : " is-invalid"
                      : "")
                  }
                  placeholder={t("register.name_placeholder")}
                  onChange={handleChange}
                />
                <div className="invalid-feedback">
                  {name.errMsg ? name.errMsg : t("register.name_required")}
                </div>
                <div className="valid-feedback">{t("register.name_valid")}</div>
              </div>

              <div className="form-outline form-white mb-2">
                <label className="form-label" htmlFor="lastname">
                  {t("register.lastname")}
                </label>
                <input
                  type="text"
                  id="lastname"
                  className={
                    "form-control form-control-lg" +
                    (lastname.touched
                      ? lastname.valid
                        ? " is-valid"
                        : " is-invalid"
                      : "")
                  }
                  placeholder={t("register.lastname_placeholder")}
                  onChange={handleChange}
                />
                <div className="invalid-feedback">
                  {lastname.errMsg
                    ? lastname.errMsg
                    : t("register.lastname_required")}
                </div>
                <div className="valid-feedback">
                  {t("register.lastname_valid")}
                </div>
              </div>

              <div className="form-outline form-white mb-2">
                <label className="form-label" htmlFor="email">
                  {t("register.email")}
                </label>
                <input
                  type="email"
                  id="email"
                  className={
                    "form-control form-control-lg" +
                    (email.touched
                      ? email.valid
                        ? " is-valid"
                        : " is-invalid"
                      : "")
                  }
                  placeholder={t("register.email_placeholder")}
                  onChange={handleChange}
                />
                <div className="invalid-feedback">
                  {email.errMsg ? email.errMsg : t("register.email_required")}
                </div>
                <div className="valid-feedback">
                  {t("register.email_valid")}
                </div>
              </div>

              <div className="form-outline form-white mb-2">
                <label className="form-label" htmlFor="password">
                  {t("register.password")}
                </label>
                <input
                  type="password"
                  id="password"
                  className={
                    "form-control form-control-lg" +
                    (password.touched
                      ? password.valid
                        ? " is-valid"
                        : " is-invalid"
                      : "")
                  }
                  placeholder={t("register.password_placeholder")}
                  onChange={handleChange}
                />
                <div className="invalid-feedback">
                  {password.errMsg
                    ? password.errMsg
                    : t("register.password_required")}
                </div>

                <div className="valid-feedback">
                  {t("register.password_valid")}
                </div>
              </div>
              <div className="form-outline form-white mb-2">
                <label className="form-label" htmlFor="password2">
                  {t("register.password2")}
                </label>
                <input
                  type="password"
                  id="password2"
                  className={
                    "form-control form-control-lg" +
                    (password2.touched
                      ? password2.valid
                        ? " is-valid"
                        : " is-invalid"
                      : "")
                  }
                  placeholder={t("register.password_placeholder")}
                  onChange={handleChange}
                />
                <div className="invalid-feedback">
                  {password2.errMsg
                    ? password2.errMsg
                    : t("register.password_required")}
                </div>

                <div className="valid-feedback">
                  {t("register.password2_valid")}
                </div>
              </div>

              <button
                className={
                  "btn btn-outline-light btn-lg px-5" +
                  (submitting ? " disabled" : "")
                }
                type="submit"
                onClick={register}>
                {submitting ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"></span>
                ) : (
                  t("register.submit")
                )}
              </button>
              <p className="form-outline">
                {t("register.login_quote")}{" "}
                <a href="/login" className="text-white-50 fw-bold">
                  {t("register.sign_in")}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
