import React, { useEffect, useState } from "react";

//import { WattrouterCard } from "./Card";
import { WattrouterLineGraph } from "./Graph";

const api_url = process.env.REACT_APP_API_URL;

export const WattrouterDashboard = (props) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = () => {
    const token = localStorage.getItem("token");
    fetch(api_url + "/device/" + props.id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          console.log(res);
        }
      })
      .then((device_data) => {
        console.log("devide.js", device_data);
        setData(device_data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  if (loading || !data) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <div className="row no-gutters p-3">
          <WattrouterLineGraph id={props.id} />
        </div>
      </>
    );
  }
};
