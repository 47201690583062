import React, { useState } from "react";
import "./settings.css";
import { putFetcher } from "../fetcher";

import { useTranslation } from "react-i18next";
import Switch from "../Settings/switch";

export const SLCSettings = (props) => {
  const { t } = useTranslation("global");
  console.log("SLCSettings", props);

  //time window 1

  const [device_config, setDeviceConfig] = useState(props.device.config);

  const handleConfigChange = (window, field, value) => {
    setDeviceConfig((prevConfig) => ({
      ...prevConfig,
      [window]: {
        ...prevConfig[window],
        [field]: value,
      },
    }));
  };

  const handleSave = () => {
    putFetcher("/device/config/" + props.device._id, {
      config: device_config,
    });

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <>
      <div className="container-fluid">
        <h1 className="h3 mb-2 text-gray-800">{t("slc_settings.title")}</h1>
        <div class="accordion" id="accordionPanelsStayOpenExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingZero">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseZero"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseZero">
                {t("slc_settings.system_deactivate")}
              </button>
            </h2>

            <div
              id="panelsStayOpen-collapseZero"
              class="accordion-collapse collapse show">
              <div class="accordion-body">
                <div className="row">
                  <div className=" col-md-4">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">
                        {t("slc_settings.enabled")}
                      </span>
                    </div>
                  </div>
                  {/*<div className="col-md-6"></div>*/}
                  <div className="col-md-2">
                    <Switch
                      isToggled={device_config.holiday_mode.enabled}
                      onToggle={(e) =>
                        handleConfigChange(
                          "holiday_mode",
                          "enabled",
                          !device_config.holiday_mode.enabled
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseOne"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseOne">
                {t("slc_settings.time_window_1")}
              </button>
            </h2>

            <div
              id="panelsStayOpen-collapseOne"
              class="accordion-collapse collapse show">
              <div class="accordion-body">
                <div className="row">
                  <div className=" col-md-4">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">
                        {t("slc_settings.enabled")}
                      </span>
                    </div>
                  </div>
                  {/*<div className="col-md-6"></div>*/}
                  <div className="col-md-2">
                    <Switch
                      isToggled={device_config.time_window_1.enabled}
                      onToggle={(e) =>
                        handleConfigChange(
                          "time_window_1",
                          "enabled",
                          !device_config.time_window_1.enabled
                        )
                      }
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.start_time")}
                      </span>
                      <input
                        type="time"
                        class="form-control"
                        placeholder="Start time"
                        aria-label="Start time"
                        aria-describedby="basic-addon1"
                        value={device_config.time_window_1.start_time}
                        onChange={(e) =>
                          handleConfigChange(
                            "time_window_1",
                            "start_time",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.end_time")}
                      </span>
                      <input
                        type="time"
                        class="form-control"
                        placeholder="End time"
                        aria-label="End time"
                        aria-describedby="basic-addon1"
                        value={device_config.time_window_1.end_time}
                        onChange={(e) =>
                          handleConfigChange(
                            "time_window_1",
                            "end_time",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.min_temperature")}
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Min temperature"
                        aria-label="Min temperature"
                        aria-describedby="basic-addon1"
                        value={device_config.time_window_1.min_temp}
                        onChange={(e) =>
                          handleConfigChange(
                            "time_window_1",
                            "min_temp",
                            e.target.value
                          )
                        }
                      />
                      <span class="input-group-text">°C</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.max_temperature")}
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Max temperature"
                        aria-label="Max temperature"
                        aria-describedby="basic-addon1"
                        value={device_config.time_window_1.max_temp}
                        onChange={(e) =>
                          handleConfigChange(
                            "time_window_1",
                            "max_temp",
                            e.target.value
                          )
                        }
                      />
                      <span class="input-group-text">°C</span>
                    </div>
                  </div>
                </div>

                <div class="row ">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span
                        class="input-group-text col-2"
                        style={{ marginRight: "10px" }}
                        id="basic-addon1">
                        {t("slc_settings.active_days")}
                      </span>
                    </div>

                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="mon"
                        checked={device_config.time_window_1.days.mon}
                        onChange={(e) =>
                          handleConfigChange("time_window_1", "days", {
                            ...device_config.time_window_1.days,
                            mon: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="mon">
                        {t("slc_settings.monday")}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="tue"
                        checked={device_config.time_window_1.days.tue}
                        onChange={(e) =>
                          handleConfigChange("time_window_1", "days", {
                            ...device_config.time_window_1.days,
                            tue: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="tue">
                        {t("slc_settings.tuesday")}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="wed"
                        checked={device_config.time_window_1.days.wed}
                        onChange={(e) =>
                          handleConfigChange("time_window_1", "days", {
                            ...device_config.time_window_1.days,
                            wed: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="wed">
                        {t("slc_settings.wednesday")}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="thu"
                        checked={device_config.time_window_1.days.thu}
                        onChange={(e) =>
                          handleConfigChange("time_window_1", "days", {
                            ...device_config.time_window_1.days,
                            thu: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="thu">
                        {t("slc_settings.thursday")}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="fri"
                        checked={device_config.time_window_1.days.fri}
                        onChange={(e) =>
                          handleConfigChange("time_window_1", "days", {
                            ...device_config.time_window_1.days,
                            fri: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="fri">
                        {t("slc_settings.friday")}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="sat"
                        checked={device_config.time_window_1.days.sat}
                        onChange={(e) =>
                          handleConfigChange("time_window_1", "days", {
                            ...device_config.time_window_1.days,
                            sat: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="sat">
                        {t("slc_settings.saturday")}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="sun"
                        checked={device_config.time_window_1.days.sun}
                        onChange={(e) =>
                          handleConfigChange("time_window_1", "days", {
                            ...device_config.time_window_1.days,
                            sun: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="sun">
                        {t("slc_settings.sunday")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseTwo">
                {t("slc_settings.time_window_2")}
              </button>
            </h2>

            <div
              id="panelsStayOpen-collapseTwo"
              class="accordion-collapse collapse">
              <div class="accordion-body">
                <div className="row">
                  <div className=" col-md-4">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">
                        {t("slc_settings.enabled")}
                      </span>
                    </div>
                  </div>
                  {/*<div className="col-md-6"></div>*/}
                  <div className="col-md-2">
                    <Switch
                      isToggled={device_config.time_window_2.enabled}
                      onToggle={(e) =>
                        handleConfigChange(
                          "time_window_2",
                          "enabled",
                          !device_config.time_window_2.enabled
                        )
                      }
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.start_time")}
                      </span>
                      <input
                        type="time"
                        class="form-control"
                        placeholder="Start time"
                        aria-label="Start time"
                        aria-describedby="basic-addon1"
                        value={device_config.time_window_2.start_time}
                        onChange={(e) =>
                          handleConfigChange(
                            "time_window_2",
                            "start_time",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.end_time")}
                      </span>
                      <input
                        type="time"
                        class="form-control"
                        placeholder="End time"
                        aria-label="End time"
                        aria-describedby="basic-addon1"
                        value={device_config.time_window_2.end_time}
                        onChange={(e) =>
                          handleConfigChange(
                            "time_window_2",
                            "end_time",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.min_temperature")}
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Min temperature"
                        aria-label="Min temperature"
                        aria-describedby="basic-addon1"
                        value={device_config.time_window_2.min_temp}
                        onChange={(e) =>
                          handleConfigChange(
                            "time_window_2",
                            "min_temp",
                            e.target.value
                          )
                        }
                      />
                      <span class="input-group-text">°C</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.max_temperature")}
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Max temperature"
                        aria-label="Max temperature"
                        aria-describedby="basic-addon1"
                        value={device_config.time_window_2.max_temp}
                        onChange={(e) =>
                          handleConfigChange(
                            "time_window_2",
                            "max_temp",
                            e.target.value
                          )
                        }
                      />
                      <span class="input-group-text">°C</span>
                    </div>
                  </div>
                </div>
                <div class="row ">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span
                        class="input-group-text col-2"
                        style={{ marginRight: "10px" }}
                        id="basic-addon1">
                        {t("slc_settings.active_days")}
                      </span>
                    </div>

                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="mon"
                        checked={device_config.time_window_2.days.mon}
                        onChange={(e) =>
                          handleConfigChange("time_window_2", "days", {
                            ...device_config.time_window_2.days,
                            mon: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="mon">
                        {t("slc_settings.monday")}
                      </label>
                    </div>
                    <div
                      class="form-check
                      form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="tue"
                        checked={device_config.time_window_2.days.tue}
                        onChange={(e) =>
                          handleConfigChange("time_window_2", "days", {
                            ...device_config.time_window_2.days,
                            tue: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="tue">
                        {t("slc_settings.tuesday")}
                      </label>
                    </div>
                    <div
                      class="form-check
                        form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="wed"
                        checked={device_config.time_window_2.days.wed}
                        onChange={(e) =>
                          handleConfigChange("time_window_2", "days", {
                            ...device_config.time_window_2.days,
                            wed: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="wed">
                        {t("slc_settings.wednesday")}
                      </label>
                    </div>
                    <div
                      class="form-check

                          form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="thu"
                        checked={device_config.time_window_2.days.thu}
                        onChange={(e) =>
                          handleConfigChange("time_window_2", "days", {
                            ...device_config.time_window_2.days,
                            thu: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="thu">
                        {t("slc_settings.thursday")}
                      </label>
                    </div>
                    <div
                      class="form-check
                              form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="fri"
                        checked={device_config.time_window_2.days.fri}
                        onChange={(e) =>
                          handleConfigChange("time_window_2", "days", {
                            ...device_config.time_window_2.days,
                            fri: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="fri">
                        {t("slc_settings.friday")}
                      </label>
                    </div>
                    <div
                      class="form-check
                                form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="sat"
                        checked={device_config.time_window_2.days.sat}
                        onChange={(e) =>
                          handleConfigChange("time_window_2", "days", {
                            ...device_config.time_window_2.days,
                            sat: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="sat">
                        {t("slc_settings.saturday")}
                      </label>
                    </div>
                    <div
                      class="form-check
                                  form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="sun"
                        checked={device_config.time_window_2.days.sun}
                        onChange={(e) =>
                          handleConfigChange("time_window_2", "days", {
                            ...device_config.time_window_2.days,
                            sun: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="sun">
                        {t("slc_settings.sunday")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingthree">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseThree"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseThree">
                {t("slc_settings.time_window_3")}
              </button>
            </h2>

            <div
              id="panelsStayOpen-collapseThree"
              class="accordion-collapse collapse">
              <div class="accordion-body">
                <div className="row">
                  <div className=" col-md-4">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">
                        {t("slc_settings.enabled")}
                      </span>
                    </div>
                  </div>
                  {/*<div className="col-md-6"></div>*/}
                  <div className="col-md-2">
                    <Switch
                      isToggled={device_config.time_window_3.enabled}
                      onToggle={(e) =>
                        handleConfigChange(
                          "time_window_3",
                          "enabled",
                          !device_config.time_window_3.enabled
                        )
                      }
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.start_time")}
                      </span>
                      <input
                        type="time"
                        class="form-control"
                        placeholder="Start time"
                        aria-label="Start time"
                        aria-describedby="basic-addon1"
                        value={device_config.time_window_3.start_time}
                        onChange={(e) =>
                          handleConfigChange(
                            "time_window_3",
                            "start_time",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.end_time")}
                      </span>
                      <input
                        type="time"
                        class="form-control"
                        placeholder="End time"
                        aria-label="End time"
                        aria-describedby="basic-addon1"
                        value={device_config.time_window_3.end_time}
                        onChange={(e) =>
                          handleConfigChange(
                            "time_window_3",
                            "end_time",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.min_temperature")}
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Min temperature"
                        aria-label="Min temperature"
                        aria-describedby="basic-addon1"
                        value={device_config.time_window_3.min_temp}
                        onChange={(e) =>
                          handleConfigChange(
                            "time_window_3",
                            "min_temp",
                            e.target.value
                          )
                        }
                      />
                      <span class="input-group-text">°C</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.max_temperature")}
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Max temperature"
                        aria-label="Max temperature"
                        aria-describedby="basic-addon1"
                        value={device_config.time_window_3.max_temp}
                        onChange={(e) =>
                          handleConfigChange(
                            "time_window_3",
                            "max_temp",
                            e.target.value
                          )
                        }
                      />
                      <span class="input-group-text">°C</span>
                    </div>
                  </div>
                </div>
                <div class="row ">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span
                        class="input-group-text col-2"
                        style={{ marginRight: "10px" }}
                        id="basic-addon1">
                        {t("slc_settings.active_days")}
                      </span>
                    </div>

                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="mon"
                        checked={device_config.time_window_3.days.mon}
                        onChange={(e) =>
                          handleConfigChange("time_window_3", "days", {
                            ...device_config.time_window_3.days,
                            mon: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="mon">
                        {t("slc_settings.monday")}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="tue"
                        checked={device_config.time_window_3.days.tue}
                        onChange={(e) =>
                          handleConfigChange("time_window_3", "days", {
                            ...device_config.time_window_3.days,
                            tue: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="tue">
                        {t("slc_settings.tuesday")}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="wed"
                        checked={device_config.time_window_3.days.wed}
                        onChange={(e) =>
                          handleConfigChange("time_window_3", "days", {
                            ...device_config.time_window_3.days,
                            wed: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="wed">
                        {t("slc_settings.wednesday")}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="thu"
                        checked={device_config.time_window_3.days.thu}
                        onChange={(e) =>
                          handleConfigChange("time_window_3", "days", {
                            ...device_config.time_window_3.days,
                            thu: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="thu">
                        {t("slc_settings.thursday")}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="fri"
                        checked={device_config.time_window_3.days.fri}
                        onChange={(e) =>
                          handleConfigChange("time_window_3", "days", {
                            ...device_config.time_window_3.days,
                            fri: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="fri">
                        {t("slc_settings.friday")}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="sat"
                        checked={device_config.time_window_3.days.sat}
                        onChange={(e) =>
                          handleConfigChange("time_window_3", "days", {
                            ...device_config.time_window_3.days,
                            sat: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="sat">
                        {t("slc_settings.saturday")}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="sun"
                        checked={device_config.time_window_3.days.sun}
                        onChange={(e) =>
                          handleConfigChange("time_window_3", "days", {
                            ...device_config.time_window_3.days,
                            sun: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="sun">
                        {t("slc_settings.sunday")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingFour">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseFour"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseFour">
                {t("slc_settings.time_window_4")}
              </button>
            </h2>

            <div
              id="panelsStayOpen-collapseFour"
              class="accordion-collapse collapse">
              <div class="accordion-body">
                <div className="row">
                  <div className=" col-md-4">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">
                        {t("slc_settings.enabled")}
                      </span>
                    </div>
                  </div>
                  {/*<div className="col-md-6"></div>*/}
                  <div className="col-md-2">
                    <Switch
                      isToggled={device_config.time_window_4.enabled}
                      onToggle={(e) =>
                        handleConfigChange(
                          "time_window_4",
                          "enabled",
                          !device_config.time_window_4.enabled
                        )
                      }
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.start_time")}
                      </span>
                      <input
                        type="time"
                        class="form-control"
                        placeholder="Start time"
                        aria-label="Start time"
                        aria-describedby="basic-addon1"
                        value={device_config.time_window_4.start_time}
                        onChange={(e) =>
                          handleConfigChange(
                            "time_window_4",
                            "start_time",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.end_time")}
                      </span>
                      <input
                        type="time"
                        class="form-control"
                        placeholder="End time"
                        aria-label="End time"
                        aria-describedby="basic-addon1"
                        value={device_config.time_window_4.end_time}
                        onChange={(e) =>
                          handleConfigChange(
                            "time_window_4",
                            "end_time",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.min_temperature")}
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Min temperature"
                        aria-label="Min temperature"
                        aria-describedby="basic-addon1"
                        value={device_config.time_window_4.min_temp}
                        onChange={(e) =>
                          handleConfigChange(
                            "time_window_4",
                            "min_temp",
                            e.target.value
                          )
                        }
                      />
                      <span class="input-group-text">°C</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.max_temperature")}
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Max temperature"
                        aria-label="Max temperature"
                        aria-describedby="basic-addon1"
                        value={device_config.time_window_4.max_temp}
                        onChange={(e) =>
                          handleConfigChange(
                            "time_window_4",
                            "max_temp",
                            e.target.value
                          )
                        }
                      />
                      <span class="input-group-text">°C</span>
                    </div>
                  </div>
                </div>
                <div class="row ">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span
                        class="input-group-text col-2"
                        style={{ marginRight: "10px" }}
                        id="basic-addon1">
                        {t("slc_settings.active_days")}
                      </span>
                    </div>

                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="mon"
                        checked={device_config.time_window_4.days.mon}
                        onChange={(e) =>
                          handleConfigChange("time_window_4", "days", {
                            ...device_config.time_window_4.days,
                            mon: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="mon">
                        {t("slc_settings.monday")}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="tue"
                        checked={device_config.time_window_4.days.tue}
                        onChange={(e) =>
                          handleConfigChange("time_window_4", "days", {
                            ...device_config.time_window_4.days,
                            tue: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="tue">
                        {t("slc_settings.tuesday")}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="wed"
                        checked={device_config.time_window_4.days.wed}
                        onChange={(e) =>
                          handleConfigChange("time_window_4", "days", {
                            ...device_config.time_window_4.days,
                            wed: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="wed">
                        {t("slc_settings.wednesday")}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="thu"
                        checked={device_config.time_window_4.days.thu}
                        onChange={(e) =>
                          handleConfigChange("time_window_4", "days", {
                            ...device_config.time_window_4.days,
                            thu: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="thu">
                        {t("slc_settings.thursday")}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="fri"
                        checked={device_config.time_window_4.days.fri}
                        onChange={(e) =>
                          handleConfigChange("time_window_4", "days", {
                            ...device_config.time_window_4.days,
                            fri: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="fri">
                        {t("slc_settings.friday")}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="sat"
                        checked={device_config.time_window_4.days.sat}
                        onChange={(e) =>
                          handleConfigChange("time_window_4", "days", {
                            ...device_config.time_window_4.days,
                            sat: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="sat">
                        {t("slc_settings.saturday")}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="sun"
                        checked={device_config.time_window_4.days.sun}
                        onChange={(e) =>
                          handleConfigChange("time_window_4", "days", {
                            ...device_config.time_window_4.days,
                            sun: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="sun">
                        {t("slc_settings.sunday")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingFive">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseFive"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseFive">
                {t("slc_settings.time_window_5")}
              </button>
            </h2>

            <div
              id="panelsStayOpen-collapseFive"
              class="accordion-collapse collapse">
              <div class="accordion-body">
                <div className="row">
                  <div className=" col-md-4">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">
                        {t("slc_settings.enabled")}
                      </span>
                    </div>
                  </div>
                  {/*<div className="col-md-6"></div>*/}
                  <div className="col-md-2">
                    <Switch
                      isToggled={device_config.time_window_5.enabled}
                      onToggle={(e) =>
                        handleConfigChange(
                          "time_window_5",
                          "enabled",
                          !device_config.time_window_5.enabled
                        )
                      }
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.start_time")}
                      </span>
                      <input
                        type="time"
                        class="form-control"
                        placeholder="Start time"
                        aria-label="Start time"
                        aria-describedby="basic-addon1"
                        value={device_config.time_window_5.start_time}
                        onChange={(e) =>
                          handleConfigChange(
                            "time_window_5",
                            "start_time",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.end_time")}
                      </span>
                      <input
                        type="time"
                        class="form-control"
                        placeholder="End time"
                        aria-label="End time"
                        aria-describedby="basic-addon1"
                        value={device_config.time_window_5.end_time}
                        onChange={(e) =>
                          handleConfigChange(
                            "time_window_5",
                            "end_time",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.min_temperature")}
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Min temperature"
                        aria-label="Min temperature"
                        aria-describedby="basic-addon1"
                        value={device_config.time_window_5.min_temp}
                        onChange={(e) =>
                          handleConfigChange(
                            "time_window_5",
                            "min_temp",
                            e.target.value
                          )
                        }
                      />
                      <span class="input-group-text">°C</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.max_temperature")}
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Max temperature"
                        aria-label="Max temperature"
                        aria-describedby="basic-addon1"
                        value={device_config.time_window_5.max_temp}
                        onChange={(e) =>
                          handleConfigChange(
                            "time_window_5",
                            "max_temp",
                            e.target.value
                          )
                        }
                      />
                      <span class="input-group-text">°C</span>
                    </div>
                  </div>
                </div>
                <div class="row ">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span
                        class="input-group-text col-2"
                        style={{ marginRight: "10px" }}
                        id="basic-addon1">
                        {t("slc_settings.active_days")}
                      </span>
                    </div>

                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="mon"
                        checked={device_config.time_window_5.days.mon}
                        onChange={(e) =>
                          handleConfigChange("time_window_5", "days", {
                            ...device_config.time_window_5.days,
                            mon: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="mon">
                        {t("slc_settings.monday")}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="tue"
                        checked={device_config.time_window_5.days.tue}
                        onChange={(e) =>
                          handleConfigChange("time_window_5", "days", {
                            ...device_config.time_window_5.days,
                            tue: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="tue">
                        {t("slc_settings.tuesday")}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="wed"
                        checked={device_config.time_window_5.days.wed}
                        onChange={(e) =>
                          handleConfigChange("time_window_5", "days", {
                            ...device_config.time_window_5.days,
                            wed: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="wed">
                        {t("slc_settings.wednesday")}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="thu"
                        checked={device_config.time_window_5.days.thu}
                        onChange={(e) =>
                          handleConfigChange("time_window_5", "days", {
                            ...device_config.time_window_5.days,
                            thu: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="thu">
                        {t("slc_settings.thursday")}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="fri"
                        checked={device_config.time_window_5.days.fri}
                        onChange={(e) =>
                          handleConfigChange("time_window_5", "days", {
                            ...device_config.time_window_5.days,
                            fri: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="fri">
                        {t("slc_settings.friday")}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="sat"
                        checked={device_config.time_window_5.days.sat}
                        onChange={(e) =>
                          handleConfigChange("time_window_5", "days", {
                            ...device_config.time_window_5.days,
                            sat: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="sat">
                        {t("slc_settings.saturday")}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="sun"
                        checked={device_config.time_window_5.days.sun}
                        onChange={(e) =>
                          handleConfigChange("time_window_5", "days", {
                            ...device_config.time_window_5.days,
                            sun: e.target.checked,
                          })
                        }
                      />
                      <label class="form-check-label" htmlFor="sun">
                        {t("slc_settings.sunday")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingSix">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseSix"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseSix">
                {t("slc_settings.export_control")}
              </button>
            </h2>

            <div
              id="panelsStayOpen-collapseSix"
              class="accordion-collapse collapse">
              <div class="accordion-body">
                <div className="row">
                  <div className=" col-md-4">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">
                        {t("slc_settings.export_control")}
                      </span>
                    </div>
                  </div>
                  {/*<div className="col-md-6"></div>*/}
                  <div className="col-md-2">
                    <Switch
                      isToggled={device_config.export_control.enabled}
                      onToggle={(e) =>
                        handleConfigChange(
                          "export_control",
                          "enabled",
                          !device_config.export_control.enabled
                        )
                      }
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.export_power")}
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Export power"
                        aria-label="Export power"
                        aria-describedby="basic-addon1"
                        value={device_config.export_control.export_power}
                        onChange={(e) =>
                          handleConfigChange(
                            "export_control",
                            "export_power",
                            e.target.value
                          )
                        }
                      />
                      <span class="input-group-text">W</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.boiler_power")}
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Boiler power"
                        aria-label="Boiler power"
                        aria-describedby="basic-addon1"
                        value={device_config.export_control.boiler_power}
                        onChange={(e) =>
                          handleConfigChange(
                            "export_control",
                            "boiler_power",
                            e.target.value
                          )
                        }
                      />
                      <span class="input-group-text">W</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.temperature")}
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Max temperature"
                        aria-label="Max temperature"
                        aria-describedby="basic-addon1"
                        value={device_config.export_control.temperature}
                        onChange={(e) =>
                          handleConfigChange(
                            "export_control",
                            "temperature",
                            e.target.value
                          )
                        }
                      />
                      <span class="input-group-text">°C</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingSeven">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseSeven"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseSeven">
                {t("slc_settings.battery_control")}
              </button>
            </h2>

            <div
              id="panelsStayOpen-collapseSeven"
              class="accordion-collapse collapse">
              <div class="accordion-body">
                <div className="row">
                  <div className=" col-md-4">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">
                        {t("slc_settings.enabled")}
                      </span>
                    </div>
                  </div>
                  {/*<div className="col-md-6"></div>*/}
                  <div className="col-md-2">
                    <Switch
                      isToggled={device_config.battery_control.enabled}
                      onToggle={(e) =>
                        handleConfigChange(
                          "battery_control",
                          "enabled",
                          !device_config.battery_control.enabled
                        )
                      }
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.min_battery")}
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="min battery"
                        aria-label="min battery"
                        aria-describedby="basic-addon1"
                        value={device_config.battery_control.min_soc}
                        onChange={(e) =>
                          handleConfigChange(
                            "battery_control",
                            "min_soc",
                            e.target.value
                          )
                        }
                      />
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.max_battery")}
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="max battery"
                        aria-label="max battery"
                        aria-describedby="basic-addon1"
                        value={device_config.battery_control.max_soc}
                        onChange={(e) =>
                          handleConfigChange(
                            "battery_control",
                            "max_soc",
                            e.target.value
                          )
                        }
                      />
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.temperature")}
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Max temperature"
                        aria-label="Max temperature"
                        aria-describedby="basic-addon1"
                        value={device_config.battery_control.temperature}
                        onChange={(e) =>
                          handleConfigChange(
                            "battery_control",
                            "temperature",
                            e.target.value
                          )
                        }
                      />
                      <span class="input-group-text">°C</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-1 ms-auto">
              <button
                type="button"
                class="btn btn-success"
                onClick={handleSave}>
                {t("slc_settings.save")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
