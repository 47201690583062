/*
Add Energy Management System device
*/

import React from "react";

const AddEMS = (props) => {
  return (
    <form>
      <h5>Energy Management System</h5>
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Name
        </label>
        <input
          type="text"
          className="form-control"
          id="name"
          aria-describedby="nameHelp"
          value={props.name}
          onChange={(e) => props.setName(e.target.value)}
        />
        <div id="nameHelp" className="form-text">
          Name of the device
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="owner" className="form-label">
          Device Serial Number
        </label>
        <input
          type="text"
          className="form-control"
          id="device_id"
          aria-describedby="deviceIdHelp"
          value={props.sn}
          onChange={(e) => props.setSN(e.target.value)}
        />
        <div id="deviceIdHelp" className="form-text">
          Serial number of the device
        </div>
      </div>
    </form>
  );
};

export default AddEMS;
